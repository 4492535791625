import React from 'react';

import styles from './styles.module.scss';

function PictaLogo() {
  return (
    <svg viewBox="0 0 93.186 29.248" className={styles.a}>
      <path
        d="M318.89,30.893a2.522,2.522,0,0,1,0-3.658,2.751,2.751,0,0,1,1.948-.734,2.815,2.815,0,0,1,1.949.7,2.26,2.26,0,0,1,.785,1.759,2.573,2.573,0,0,1-.769,1.9,2.654,2.654,0,0,1-1.965.768A2.754,2.754,0,0,1,318.89,30.893Zm-.2,3.742h4.272V52.887h-4.276Z"
        transform="translate(-286.11 -23.835)"
      />
      <path
        d="M411.924,120.824a8.786,8.786,0,0,1-3.52-3.333,9.3,9.3,0,0,1-1.264-4.837,9.209,9.209,0,0,1,1.264-4.819,8.851,8.851,0,0,1,3.5-3.319,10.694,10.694,0,0,1,5.11-1.2,9.843,9.843,0,0,1,4.733,1.094,7.1,7.1,0,0,1,3.059,3.144l-3.281,1.911a5.214,5.214,0,0,0-1.964-1.9,5.756,5.756,0,0,0-6.547.928,6.306,6.306,0,0,0-.013,8.306,5.754,5.754,0,0,0,6.559.923,5.214,5.214,0,0,0,1.964-1.9l3.281,1.911a7.4,7.4,0,0,1-3.093,3.162,9.678,9.678,0,0,1-4.7,1.106A10.645,10.645,0,0,1,411.924,120.824Z"
        transform="translate(-366.191 -92.929)"
      />
      <path
        d="M611.188,86.583a5.053,5.053,0,0,1-1.828.922,8.233,8.233,0,0,1-2.273.308,6.448,6.448,0,0,1-4.649-1.573,6.051,6.051,0,0,1-1.64-4.579V72.878H597.79v-3.42H600.8V65.29h4.272v4.169h4.887v3.42h-4.888V81.56a2.89,2.89,0,0,0,.65,2.034,2.439,2.439,0,0,0,1.879.7,3.755,3.755,0,0,0,2.393-.751Z"
        transform="translate(-537.666 -58.724)"
      />
      <path
        d="M28.723,7.839c-.212-4.072-4.381-7.574-9.3-7.8A19.925,19.925,0,0,0,3.194,6.487,10.477,10.477,0,0,0,.313,16.454a4.639,4.639,0,0,0,1.339,2.23c1.495,1.43,3.573.589,2.985-1.442-.883-3.053.01-5.888,3-8.75A23.4,23.4,0,0,1,9.8,6.681L9.89,29.095h4.294V24.68a3.8,3.8,0,0,1,1.376-2.864A5.182,5.182,0,0,1,17.37,20.8c.587-.193,1.278-.441,1.278-.441C25.046,18.045,29,13.131,28.723,7.839Zm-13.652,9.4c-.279.112-.6.223-.892.313V4.587a18.436,18.436,0,0,1,4.985-.841c2.04-.064,3.577.368,4.441,1.262a3.681,3.681,0,0,1,.88,2.929C24.223,11.88,20.968,15.1,15.071,17.239Z"
        transform="translate(-0.003 -0.004)"
      />
      <path
        d="M754.131,103.061h-4.162v1.456a9.462,9.462,0,1,0,0,15.176v1.717h4.157V103.337Zm-9.778,14.644a5.632,5.632,0,1,1,5.617-6.035v.8A5.632,5.632,0,0,1,744.353,117.705Z"
        transform="translate(-660.945 -92.32)"
      />
    </svg>
  );
}

export default PictaLogo;
